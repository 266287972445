import React, { Component } from "react";
import { enquireScreen } from "enquire-js";
import { injectIntl } from "gatsby-plugin-intl";

import {
  BannerDataSource,
  RelatedProductsDataSource,
  FAQDataSource,
  BenefitDataSource
} from "../../../data/cmnd.data";
import { ContactFormDataSource } from "../../../data/home.data";
import loadable from "@loadable/component";

const Layout = loadable(() => import("../../../components/Layout"));
const SEO = loadable(() => import("../../../components/SEO"));
const Banner = loadable(() => import("../../../components/Banner"));

const ContactForm = loadable(() =>
  import("../../../components/Home/ContactForm")
);
const RelatedProducts = loadable(() =>
  import("../../../components/RelatedProducts")
);
const FAQ = loadable(() => import("../../../components/FAQ"));
const Demo = loadable(() => import("../../../components/OCR/Demo"));
const Benefit = loadable(() => import("../../../components/Benefit"));

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

class CMNDPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    const { intl } = this.props;
    return (
      <>
        <SEO title={intl.formatMessage({ id: "seo.title_page211" })} pathname="/products/ocr/vietnam-id-cards" />
        <Layout>
          <Banner
            id="Banner0_3"
            key="Banner0_3"
            dataSource={BannerDataSource}
            isMobile={this.state.isMobile}
          />
          <Demo isMobile={this.state.isMobile} />
          <Benefit
            id="Benefit"
            key="Benefit"
            dataSource={BenefitDataSource}
            isMobile={this.state.isMobile}
          />
          <ContactForm
            id="ContactForm"
            key="ContactForm"
            dataSource={ContactFormDataSource}
            isMobile={this.state.isMobile}
          />
          <FAQ
            id="FAQ"
            key="FAQ"
            dataSource={FAQDataSource}
            isMobile={this.state.isMobile}
          />
          <RelatedProducts
            id="RelatedProducts"
            key="RelatedProducts"
            dataSource={RelatedProductsDataSource}
            isMobile={this.state.isMobile}
          />
        </Layout>
      </>
    );
  }
}

export default injectIntl(CMNDPage);
